input[type=text],input[type=email],input[type=tel] {
    width: 49%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box ;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #f5f5ff;
    border: 0;
 
    border-radius: .25rem;
}
textarea{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #f5f5ff;
    border: 0;
    border-radius: .25rem;
}
input[type=email],input[type=tel]{
    margin-left:2%;
}
 .subBtn{
    background:rgb(81, 176, 255);
    color: white;
    border:none;
    font-size: 15px;
    border-bottom:.4rem solid darken(rgb(81, 176, 255),10%);
  
}
iframe{
    width: 100%;
}
.contactIconwrap{
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: row;
}
.contactIconwrap > div{
    height: 180px;
    border-radius: 4%;
    width: 213px;
    font-size: 15px;
    margin: 6px;
   box-shadow: 0 5px 20px rgba(9, 46, 237, 0.1);
    text-align: center;
    padding: 20px 10px 10px 10px;

}
.contactIcon{
font-size: 30px;
color:rgb(81, 176, 255);
}
.iconCont{
    height: 50px;
    text-align: center;
    // background: #F5F5FF;
    padding-top: 8px;
    width: 100%;
    border-radius: 19px;
}

@media only screen and (max-width: 725px){
    input[type=text],input[type=email],input[type=tel],textarea {
        width: 100%;
    }
    input[type=email],input[type=tel]{
        margin-left:0;
    }
    .content{
        font-size: 15px;
        padding: 20px 45px;
    }
    .contactIconwrap{
      flex-direction: column;
    }
    .contactIconwrap > div{
      margin-top: 10px;
        width: 100%;
        font-size: 20px;
     
    }
    }