.oops{
    font-size: 20px;
    line-height: 30px;
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    color: #6d70a6;
}
.align_text{
    text-align: center;
}
.notFound{
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: .5rem;
    font-family: inherit;
}
.four{
    font-weight: 700;
    font-size: 200px;
    line-height: 210px;
    background: linear-gradient(-50deg, #0985f9 30%, #6809dc 100%);
    background-color: rgba(0, 0, 0, 0);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: transparent;
    margin-bottom: 0.2em;
}