.is-active{
  border-bottom: 2px solid rgb(114, 114, 248);
}
.headerlogo{
  height: 95px;
}
.logoTitle{
  font-size: 40px;
  font-family: initial;
  color: darkgray;

  // color:rgb(114, 114, 248);
}
.navbar-light .navbar-nav .nav-link {
  color: darkslategrey;
  margin-left: 39px;
}

.navbar {
    box-shadow: rgba(38,50,56,0.07) 0px 7px 50px 0px;
}




@media only screen and (max-width: 800px){
  .logoContainer{
    display: flex;
    align-items: center;
  }
.headerlogo {
  height: 126px;
}
}